.bn-onboard-custom.bn-onboard-modal-content.bn-onboard-dark-mode {
    background: #1e2738;
}

.bn-onboard-custom.bn-onboard-icon-button.bn-onboard-dark-mode-background-hover:hover {
    background: #151B28;
}

.bn-onboard-custom.bn-onboard-selected-wallet.bn-onboard-dark-mode-background-hover {
    background: #151B28;
}
