body {
  background: #000;
  color: white !important;
  overflow-y: scroll !important;
  margin: 0;
  font-size: 14px;
  font-family: 'rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: white;
  transition: all 0.2s ease-out;
}
a:visited { text-decoration: none; color: white; }
a:focus { text-decoration: none; color: white; }
a:hover, a:active {
  text-decoration: underline;
  color: white;
  transition: all 0s;
}

th {
  font-weight: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

@font-face {
  font-family: 'rubik';
  src: local('rubik'), url(./fonts/Rubik-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'rubik';
  src: local('rubik'), url(./fonts/Rubik-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'rubik';
  src: local('rubik'), url(./fonts/Rubik-Bold.ttf) format('truetype');
  font-weight: bold;
}
