.react-date-picker__wrapper {
  display: none !important;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}

.iceCREAM-calendar {
  background: black;
  font-family: inherit;
}

.iceCREAM-calendar .react-calendar__navigation__label {
  color: white;
  font-weight: 500;
}

.iceCREAM-calendar .react-calendar__navigation__arrow {
  color: #69E2DB;
  font-weight: 500;
}

.iceCREAM-calendar .react-calendar__navigation button:enabled:hover, .iceCREAM-calendar .react-calendar__navigation button:enabled:focus {
  background-color: unset;
}

.iceCREAM-calendar .react-calendar__navigation button[disabled] {
  background-color: unset;
  color: rgba(230, 230, 230, 0.3);
}

.iceCREAM-calendar .react-calendar__tile {
  background-color: unset;
  color: #69E2DB;
  font-weight: 500;
}

.iceCREAM-calendar .react-calendar__tile:disabled {
  background-color: unset;
  color: rgba(230, 230, 230, 0.3);
}

.iceCREAM-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(230, 230, 230, 0.8);
}

.iceCREAM-calendar .react-calendar__tile:enabled:hover, .iceCREAM-calendar .react-calendar__tile:enabled:focus {
  background-color: white;
  color: black;
}

