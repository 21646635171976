.modal-appear {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.modal-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 200ms;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: all 200ms;
}
